@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  input[type='number']::-webkit-inner-spin-button,
  input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  input[type='number'] {
    -moz-appearance: textfield;
  }

  .scrollbar::-webkit-scrollbar {
    width: 7px;
  }

  .scrollbar::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 2px;
  }

  .scrollbar::-webkit-scrollbar-thumb {
    border-radius: 2px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.7);
    box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.7);
  }
}

@layer components {
  select:disabled,
  button:disabled,
  input:disabled {
    @apply cursor-not-allowed opacity-50;
  }
}

/* Muda o hover do dia do calendário da lib day-picker */
.rdp-day:hover {
  color: blue;
  opacity: 1;
  background-color: var(--rdp-accent-color);
}

/* Desabilita badge do recaptcha v3 */
.grecaptcha-badge { visibility: hidden; }